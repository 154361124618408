import React from 'react'
import { Col, Layout, Row } from 'antd'
import MainMenu from './mainMenu'
import Logo from './logo'
import classNames from 'classnames'

type HeaderProps = {
	invert: boolean
}

const Header: React.FC<HeaderProps> = ({ invert }) => {
	return (
		<Layout.Header id={'header'}>
			<div className={classNames('container')}>
				<Row>
					<Col span={6}>
						<Logo invert={invert} />
					</Col>
					<Col span={18}>
						<MainMenu />
					</Col>
				</Row>
			</div>
		</Layout.Header>
	)
}

export default Header
