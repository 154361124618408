import React from 'react'
import { ConfigProvider } from 'antd'

// Define props type for config antd component
type ConfigAntdProps = {} & React.PropsWithChildren<{}>

// Config antd component
const ConfigAntd: React.FC<ConfigAntdProps> = ({ children }) => {
	return <ConfigProvider>{children}</ConfigProvider>
}

export default ConfigAntd
