import React from 'react'
import { Link } from 'react-router-dom'

type LogoProps = {
	invert: boolean
}

const Logo: React.FC<LogoProps> = ({ invert }) => {
	return <Link id={'logo'} to={'/'} title={'Veronika Koňaková'} />
}

export default Logo
