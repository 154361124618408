import React from 'react'
import ConfigAntd from './configAntd'
import ErrorBoundary from './errorBoundary'

// Define props type for config antd component
type ConfigProps = {} & React.PropsWithChildren<{}>

// Config component
const Config: React.FC<ConfigProps> = ({ children }) => {
	return (
		<ErrorBoundary>
			<ConfigAntd>{children}</ConfigAntd>
		</ErrorBoundary>
	)
}

export default Config
