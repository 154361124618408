import React from 'react'
import { Col, Layout, Row } from 'antd'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
	return (
		<Layout.Footer id={'footer'}>
			<div className={classNames('container')}>
				<Row>
					<Col sm={24} md={24} lg={12}>
						<div className={classNames('kdpcr footer-logo')}>
							<h2>Člen KomorY daňových poradců ČR</h2>
							<span>Daňový poradce s evidenčním číslem 5535</span>
						</div>
						<div className={classNames('vk footer-logo')}>
							<h2>Ing. Veronika Koňaková</h2>
							<span>Účetní a daňové poradenství</span>
						</div>
						<div className={'copyright'}>
							<strong>{new Date().getFullYear()} © Ing. Veronika Koňaková</strong>, všechna
							práva vyhrazena. Použít texty, fotografie, videa, obrázky je povoleno jen se
							souhlasem provozovatele stránek.
						</div>
					</Col>

					<Col sm={24} md={24} lg={6}>
						<div className={classNames('sitemap')}>
							<h3>Externí odkazy</h3>
							<ul>
								<li>
									<a href={'https://www.kdpcr.cz/'} target={'_blank'}>
										Komora daňových poradců
									</a>
								</li>
								<li>
									<a href={'http://wwwinfo.mfcr.cz/ares/ares_es.html.cz'} target={'_blank'}>
										ARES - Ekonomické subjkety
									</a>
								</li>
								<li>
									<a href={'http://ec.europa.eu/taxation_customs/vies/'} target={'_blank'}>
										VIES - Subjekty DPH - EU
									</a>
								</li>
								<li>
									<a href={'http://adisreg.mfcr.cz/cgi-bin/adis/idph/int_dp_prij.cgi?ZPRAC=FDPHI1&poc_dic=2'} target={'_blank'}>
										Subjekty DPH - ČR
									</a>
								</li>
								<li>
									<a href={'https://www.cnb.cz/cs/financni-trhy/devizovy-trh/kurzy-devizoveho-trhu/'} target={'_blank'}>
										ČNB - devizové kurzy
									</a>
								</li>
								<li>
									<a href={'https://adisdis.mfcr.cz/adistc/adis/idpr_pub/dpr/uvod.faces'} target={'_blank'}>
										Daňový portál
									</a>
								</li>
							</ul>
						</div>
					</Col>

					<Col sm={24} md={24} lg={6}>
						<div className={classNames('sitemap')}>
							<h3>Menu</h3>
							<ul>
								<li>
									<Link to={'/'}>Domů</Link>
								</li>
								<li>
									<Link to={'/tax-calendar'}>Daňový kalendář</Link>
								</li>
								<li>
									<Link to={'/download'}>Ke stažení</Link>
								</li>
								<li>
									<Link to={'/blog'}>Blog</Link>
								</li>
								<li>
									<Link to={'/contact'}>Kontakt</Link>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
			</div>
		</Layout.Footer>
	)
}

export default Footer
