import React from 'react'
import { Layout } from 'antd'
import Routes from './routes'

const Content: React.FC = () => {
	return (
		<Layout.Content id={'content'}>
			<Routes />
		</Layout.Content>
	)
}

export default Content
