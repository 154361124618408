import React from 'react'
import { Container } from '../../../components'
import { Carousel } from 'antd'
import Card from './card'
import {default as testimonials} from '../../../testimonials.json'

const Testimonial: React.FC = () => {
	return (
		<Container
			id={'testimonial'}
			title={'Reference'}
			subtitle={
				'Přečtěte si jak spolupráci se mnou hodnotí někteří moji klienti'
			}
		>
			<Carousel
				autoplay={true}
				effect={'scrollx'}
				dotPosition={'bottom'}
				dots={true}
				className={'carousel'}
				autoplaySpeed={10000}
				easing={'linear'}
			>
				{testimonials.map((item, index) => (
					<div key={index}>
							<Card
								avatar={item.avatar}
								text={item.text}
								name={item.name}
								job={item.job}
								rating={item.rating}
							/>
					</div>
				))}
			</Carousel>
		</Container>
	)
}

export default Testimonial
