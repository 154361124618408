import React, { useState } from 'react'
import { Drawer } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {useScreen} from "../../../hooks"

type CardProps = {
	icon: IconProp | any
	name: string
	briefDescription: string
	description: string
}

const Card: React.FC<CardProps> = ({ icon, name, briefDescription, description }) => {
	const [visible, setVisible] = useState(false)

	const handleCardClick = () => setVisible(true)
	const handleOnClose = () => setVisible(false)

	const [isXS, isSM, isMD, isLG, isXL] = useScreen()
	const drawerWidth = isXS || isSM || isMD ? '100%' : (isLG ? '70%' : (isXL ? '50%' : '40%'))


	const renderIcon = (
		<div className={'icon'}>
			<FontAwesomeIcon icon={icon} />
		</div>
	)

	const renderName = (
		<div className={'title'}>
			<h5>{name}</h5>
		</div>
	)

	const renderBriefDescription = (
		<div className={'brief-description'}>
			<span>{briefDescription}</span>
		</div>
	)

	const renderDescription = (
		<div className={'description'}>
			<span dangerouslySetInnerHTML={{ __html: description }} />
		</div>
	)

	const renderDrawerHeader = (
		<div>
			{renderIcon}
			{renderName}
		</div>
	)

	return (
		<React.Fragment>
			<div className={'service-card'} onClick={handleCardClick}>
				<div className={'left'}>{renderIcon}</div>
				<div className={'right'}>
					{renderName}
					{renderBriefDescription}
				</div>
			</div>
			<Drawer
				closable={true}
				visible={visible}
				onClose={handleOnClose}
				title={renderDrawerHeader}
				height={'100%'}
				width={drawerWidth}
				placement={'right'}
				className={'service-card-drawer'}
			>
				{renderBriefDescription}
				{renderDescription}
			</Drawer>
		</React.Fragment>
	)
}

export default Card
