import React from 'react'
import ReactDOM from 'react-dom'
// Import config component
import App from './app'
// register all font awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons'
// Import service worker
import * as serviceWorker from './serviceWorker'
// Import styles
import './styles'

// register all font awesome icons
const iconList = Object.keys(Icons)
    .filter(key => key !== 'fas' && key !== 'prefix')
    .map(icon => (Icons as any)[icon])

library.add(...iconList)

// Render react app
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
