import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Col, Row } from 'antd'

type ContainerProps = {
	id: string
	title: string
	subtitle: string
} & PropsWithChildren<{}>

const Container: React.FC<ContainerProps> = ({ id, title, subtitle, children }) => {
	return (
		<div id={id}>
			<div className={classNames('container')}>
				<Row>
					<Col span={24}>
						<h3 className={'title'}>{title}</h3>
						<h4 className={'subtitle'}>{subtitle}</h4>
					</Col>
					<Col span={24}>
						<div className={'content'}>{children}</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default Container
