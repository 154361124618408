import React from 'react'
import IOptions from './interfaces/options'

// destruct react
const { useEffect, useState } = React

// default options
const defaultOptions: IOptions = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
}

const useScreen = (options: IOptions = defaultOptions): boolean[] => {
    //
    const { xs, sm, md, lg, xl, xxl } = options

    //
    const [isXS, setIsXS] = useState(false)
    const [isSM, setIsSM] = useState(false)
    const [isMD, setIsMD] = useState(false)
    const [isLG, setIsLG] = useState(false)
    const [isXL, setIsXL] = useState(false)
    const [isXXL, setIsXXL] = useState(false)
    const [is3XL, setIs3XL] = useState(false)

    useEffect(() => {
        // init
        setValues()
        // add listener
        window.addEventListener('resize', handleResize)
        // remove listener
        return (): void => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // set values
    const setValues = (): void => {
        // get window width
        const width = window.innerWidth
        // set by options
        setIsXS(width <= xs)
        setIsSM(width <= sm)
        setIsMD(width <= md)
        setIsLG(width <= lg)
        setIsXL(width <= xl)
        setIsXXL(width <= xxl)
        setIs3XL(width > xxl)
    }

    // on resize handle
    const handleResize = (): void => {
        setValues()
    }

    return [isXS, isSM, isMD, isLG, isXL, isXXL, is3XL]
}

export default useScreen
