import React from 'react'
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom'
import { ContactPage, HomePage, UnderConstructionPage } from '../../containers'

type RoutesProps = {} & RouteComponentProps

const Routes: React.FC<RoutesProps> = props => {
	return (
		<Switch>
			<Route path={'/'} component={HomePage} exact={true} />
			<Route path={'/tax-calendar'} component={UnderConstructionPage} />
			<Route path={'/download'} component={UnderConstructionPage} />
			<Route path={'/blog'} component={UnderConstructionPage} />
			<Route path={'/contact'} component={ContactPage} />
		</Switch>
	)
}

export default withRouter(Routes)
