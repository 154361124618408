import React from 'react'
import { Button, Col, Row } from 'antd'
import classNames from 'classnames'

const Banner: React.FC = () => {
	return (
		<div id={'banner'}>
			<div className={classNames('container')}>
				<Row>
					<Col span={12} className={classNames('banner-text-container')}>
						<div className={classNames('banner-text')}>
							<h1>Veronika Koňaková</h1>
							<h2>Účetní a daňové poradenství</h2>
							<Button type={'primary'} ghost={true} href={process.env.PUBLIC_URL + '/download/vizitky.pdf'} target={'_blank'}>
								Stáhnout vizitku
							</Button>
						</div>
					</Col>
					<Col span={12}>
						<div className={classNames('banner-image')} />
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default Banner
