import React from 'react'

// Define state type for error boundary component
type ErrorBoundaryState = {
	hasError: boolean
}

// Define props type for error boundary component
type ErrorBoundaryProps = {
	// Write custom props here
} & React.PropsWithChildren<{}>

// Error Boundary component
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props)
		// init default local state
		this.state = { hasError: false }
		// Bind this for methods
		this.renderFallbackUI = this.renderFallbackUI.bind(this)
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		// You can also log the error to an error reporting service
	}

	renderFallbackUI() {
		return <React.Fragment />
	}

	render() {
		// destruct state & props
		const { hasError } = this.state
		const { children } = this.props
		// check if hasError is true or not
		return !hasError ? children : this.renderFallbackUI()
	}
}

export default ErrorBoundary
