import React from 'react'
import { Col, Row } from 'antd'
import { Container } from '../../components'

const ContactInfo: React.FC = () => {
	return (
		<Container
			id={'contact-info'}
			title={'Kontaktní informace'}
			subtitle={
				'V případě zájmu o spolupráci se na mě můžete kdykoliv obrátit prostřednictvím zde uvedených kontaktů.'
			}
		>
			<Row gutter={0}>
				<Col span={12}>
					<div className={'left info-container'}>
						<h3>Ing. Veronika Koňaková</h3>
						<h4>Účetní a daňové poradenství</h4>

						<br />
						<strong>Adresa</strong>
						<br />
						<span>Čumpelíkova 1010/5, Praha 18200</span>

						<br />
						<br />
						<strong>Mobil</strong>
						<br />
						<span>(+420) 737 394 421</span>

						<br />
						<br />
						<strong>Email</strong>
						<br />
						<span>me@veronikakonakova.cz</span>
					</div>
				</Col>
				<Col span={12}>
					<div className={'right info-container'}>
						<h3>Člen Komory daňových poradců ČR</h3>
						<h4>Daňový poradce s ev. číslem 5535</h4>

						<br />
						<strong>IČO</strong>
						<br />
						<span>08667446</span>

						<br />
						<br />
						<strong>DIČ</strong>
						<br />
						<span>CZ685015682</span>

						<br />
						<br />
						<strong>Datová schránka</strong>
						<br />
						<span>xw2hmej</span>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default ContactInfo
