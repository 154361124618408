import React from 'react'
import { Avatar, Col, Row } from 'antd'

type CardProps = {
	avatar: string
	text: string
	name: string
	job: string
	rating: number
}

const Card: React.FC<CardProps> = ({ avatar, text, name, job, rating }) => {
	const colors = ['#18c9de', '#e25d75', '#a478d4', '#89b13a', '#3cb1b7']

	const numberFromText = (text: string) => {
		// numberFromText("AA");
		const charCodes = text
			.split('') // => ["A", "A"]
			.map(char => char.charCodeAt(0)) // => [65, 65]
			.join('') // => "6565"
		return parseInt(charCodes, 10)
	}

	const renderAvatar = () => {
		if (!!avatar) return <Avatar className={'avatar'} src={avatar} />
		const splitName = name.split(' ')
		let avatarText = 'NO_TEXT'
		if (splitName.length > 1) avatarText = `${splitName[0][0]}${splitName[1][0]}`
		if (avatarText === 'NO_TEXT') return <Avatar className={'avatar icon'} icon={'user'} />
		return (
			<Avatar
				className={'avatar text'}
				style={{
					backgroundColor: colors[numberFromText(avatarText) % colors.length],
				}}
			>
				{avatarText}
			</Avatar>
		)
	}

	return (
		<div className={'testimonial-card'}>
			<Row>
				<Col span={24}>{renderAvatar()}</Col>
				<Col span={24}>
					<span className={'text'}>{text}</span>
				</Col>
				<Col span={24}>
					<h5 className={'name'}>{name}</h5>
				</Col>
				<Col span={24}>
					<h6 className={'job'}>{job}</h6>
				</Col>
				{/*<Col span={24}>*/}
				{/*	<Rate allowHalf defaultValue={rating} disabled={true} className={'rating'} />*/}
				{/*</Col>*/}
			</Row>
		</div>
	)
}

export default Card
