import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Layout from "./layout"

const WebLayout: React.FC = () => {
	return (
		<BrowserRouter>
			<Layout />
		</BrowserRouter>
	)
}

export default WebLayout
