import React from 'react'
import { Button, Col, Row } from 'antd'
import Card from './card'
import { Container } from '../../../components'
import * as _ from 'lodash'

const data = [
	{
		icon: 'file-invoice',
		name: 'Vedení účetnictví a daňové evidence',
		briefDescription:
			'Využitím vedení účetnictví prostřednictvím externí účetní šetříte finanční prostředky. Nevytváříte pracovní místo a další náklady spojené s vedením účetnictví. Jako externí účetní za Vás sleduji změny zákonů, legislativy, využívám svůj vždy aktuální software a pravidelně se vzdělávám. Své účetnictví pak máte vždy dle aktuální legislativy.',
		description: '<h3>Více o službě</h3><p>Vedení účetnictví je hlavním oborem moji činnosti. Upřednostňuji dlouhodobou spolupráci, která je výhodná pro obě strany. S klientem se dobře znám, jsem schopná pochopit jeho podnikání a způsob fungování, neustále spolupracujeme na zlepšování procesů v rámci předávaní dokladů a podnikání, optimalizaci nákladů. Také má klient neustále přehled o svém podnikání prostřednictvím průběžných výsledků, které spolu konzultujeme a rovněž má pravidelnou představu o svých daňových povinnostech.</p><br /><h3>Co služba zahrnuje</h3><p>Doklady dodané k zpracování zatřídím, zaúčtuji (vedu veškerou povinnou agendu), založím do šanonů příslušných agend, vytisknu výstupy, tj. provádím kompletní archivaci. Zasílám všechny potřebné výstupy na příslušné orgány. Starám se o datovou schránku. V složitějších případech provádím i fakturaci dle pokynů klientů.</p>',
	},
	{
		icon: 'calculator',
		name: 'Zpracování daňového tvrzení',
		briefDescription: 'Zpracování všech druhů daňových tvrzení tj. přiznání k dani z příjmu fyzických osob, k dani z příjmu právnických osob, k dani z přidané hodnoty, k dani silniční a dalších typů tvrzení dle potřeby konkrétní obchodní korporace nebo fyzické osoby. Pro fyzické osoby zpracovávam přehledy pro zdravotní pojišťovny a OSSZ.',
		description: '<h3>Více o službě</h3><p>Samozřejmosti je, že jako daňový poradce mohu poskytnou odklad k podaní daňového přiznání a tím prodloužení odevzdání daňového přiznání z příjmů pravnických i fyzických osob do konce června, které znamená i posunutí termínu platby daně, která je splatná také do konce června.</p><br/><h3>Co služba zahrnuje</h3><p>U stálých klientům je jenom vyústěním dlouhodobé spolupráce. Všechny potřebné poklady mám již průběžně zpracované. S klientem doladíme jenom posledné úpravy a následně vytvořím daňové trvzení a všechny výstupy odešlu na potřebné instituce. <br/><br/>U jednorázového nebo nepravidelného zpracování tvrzení klientům doručím manuál se seznamem potřebných dokumentů a informací. Následně vše potřebné zpracuji, vytvořím tvrzení a všechny výstupy odešlu na potřebné instituce.</p>',
	},
	{
		icon: 'building',
		name: 'Daňové poradenství a zastupování před úřady',
		briefDescription: 'Daňové konzultace o aktuálních problémech klientů jsou nezbytnou součásti moji činnosti. Vždy se snažím problém s klientem dokonale rozebrat, abych pochopila problematiku a klientovi pomohla na 100 %. Řešení poskytnu jak v odborné i laické formě, je pro mě velmi důležité, aby klient odpověď pochopil a odcházel spokojený.',
		description: '<h3>Více o službě</h3><p>V rámci daňového poradenství komunikuji za klienta s finančním úřadem na základě plné moci, připravuji poklady a zastupuji ho při kontrolách finančního úřadu. Zastupování se taky týká i jiných souvisejících úřadu (správa sociálního zabezpečení, zdravotní pojišťovny, úřady práce, živnostenské úřady).</p>',
	},
	{
		icon: 'money-bill',
		name: 'Odpovědnost a cenotvorba',
		briefDescription: 'Dvě věci, které mě odlišují od konkurence. Mám profesní pojištění odpovědnosti za případnou škodu způsobenou klientům v oblasti účetnictví, administrativy a především daní. Rovněž mě odlišuje také pravidelná cenotvorba. Ceny pro každého klienta stanovuji individuálně, pravidelně je vyhodnocuji a upravuji směrem nahoru ale i dolů.',
		description: '<h3>Více o službě</h3><p>Mám profesní pojištění odpovědnosti za případnou škodu způsobenou klientům jak v oblasti účetnictví a administrativy, tak i v oblasti daňové, co je moji obrovskou výhodou oproti účetním. Účetní je specialista na vedení účetnictví může být profesně pojištěn jenom na účetnictví, ale pojištění nekryje chyby, které udělá v daňovém přiznání. Jako účetní totiž nemá pro tuto činnost oprávnění. Je obrovskou výhodou mít účetní, která je současné i daňovým poradcem. Každý daňový poradce je povinně profesně pojištěn, jak vyplývá z pravidel KDPČR. <br/><br/>Ceny se v účetnictví stanovují velmi obtížně, pro každého klienta jsou stanoveny individuálně, v závislosti na druhu podnikání, množství dokladů, jejich uspořádání a náročnosti zpracování.Obdobně je to i s cenou za daňové služby a poradenství, která závisí na náročnosti dotazů, jednorázového zpracování přiznání a také náročnosti a délce zastupování před úřady. Účetní klienti mají běžné daňové poradenství v ceně za účetní služby.Cena vychází ze skutečné pracnosti, která je závislá na množství dodaných podkladů a jejich rozpracovanosti a také na pořádnosti klienta a rozsahu služeb. Záleží mi predevším na tom, aby klient přesně a jasně věděl, za co u mě platí.</p>',
	},
	{
		icon: 'calendar-alt',
		name: 'Vedení mzdového účetnictví a personalistika',
		briefDescription: 'Výpočet mezd, odvodů sociálního a zdravotního pojištění včetně rozeslání výplatních pásek, roční zúčtování daně zaměstnancům, roční vyúčtování záloh na daně ze závislé činnosti, roční vyúčtování srážkové daně, odevzdávání přehledů zdravotním pojišťovnám a správě sociálního zabezpečení, ELDP a další související operace.',
		description: '<h3>Více o službě</h3><p>Dále vedení veškeré personální agendy, přihlašování a odhlašování zaměstnanců ke zdravotnímu a sociálnímu pojištění, hlášení o zaměstnávání důchodců a další úkony spojené s vedením personální agendy. Vypracování pracovních smluv, nástupy a odchody zaměstnanců.</p>',
	},
	{
		icon: 'thumbs-up',
		name: 'Strategické poradenství, funkční a pravidelní servis',
		briefDescription: 'Poskytnu poradenství v rámci vedení obchodní korporace/osoby samostatně výdelečně činné/fyzické osoby s ohledem na účetní výsledky a daňové povinnosti. Zahrnuje zejména posouzení podnikatelských záměrů z daňového hlediska a daňové plánování – minimalizace daňové zátěže klienta s ohledem na očekávaný rozvoj firmy.',
		description: '<h3>Více o službě</h3><p>Všem klientům se snažím vždy maximálně vyhovět poskytnou jim nadstandardní péči při plnění všech povinností vyplívajících z daňových a účetních zákonu, předpisů, standardů atd. Navrhovat jim možné řešení jak daňových problémů, tak i rady k celkovému fungování v podnikaní. Upozorňovat jich na daňové termíny a splatnosti daní a daňových záloh. <br/><br/>Spokojenost klientů je pro mě prvořadá.</p>',
	},
]

const Services: React.FC = () => {
	const [toggle, setToggle] = React.useState(true)

	const handleToggleClick = () => setToggle(!toggle)
	const expandEnabled= data.length > 6
	const takeSize = expandEnabled ?  4 : data.length

	const renderServices = () => {
		return _.map(_.take(data, toggle ? takeSize : data.length), (item, index) => {
			return (
				<Col sm={24} md={24} lg={24} xl={12} key={index}>
					<Card icon={item.icon} name={item.name} briefDescription={item.briefDescription} description={item.description} />
				</Col>
			)
		})
	}

	return (
		<Container
			id={'services'}
			title={'Služby'}
			subtitle={
				'Mnou poskytnuté služby Vám zajistí spokojenost a jistotu tak, aby jste se Vy mohli věnovat svému podnikání.'
			}
		>
			<Row gutter={16}>{renderServices()}</Row>
			{expandEnabled && (
				<Row gutter={16}>
					<Col span={24}>
						<Button type={'primary'} className={'secondary'} onClick={handleToggleClick}>
							{toggle ? 'Zobrazit více' : 'Zobrazit méně'}
						</Button>
					</Col>
				</Row>
			)}
		</Container>
	)
}

export default Services
