import Config from './config'
import React from 'react'
import WebLayout from './layouts/web'

// App component
const App: React.FC = () => {
	return (
		<Config>
			<WebLayout />
		</Config>
	)
}

export default App
