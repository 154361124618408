import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { BackTop, Layout as AntdLayout } from 'antd'
import Header from './header'
import Content from './content'
import Footer from './footer'

type LayoutProps = {} & RouteComponentProps

const Layout: React.FC<LayoutProps> = props => {
	// On navigate set scroll top to 0
	window.scrollTo(0, 0)

	return (
		<AntdLayout id={'layout'} className={classNames({ 'invert-color': false })}>
			<Header invert={false} />
			<Content />
			<Footer />
			<BackTop visibilityHeight={1000} />
		</AntdLayout>
	)
}

export default withRouter(Layout)
