import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type CardProps = {
	icon: IconProp
	title: string
	content: string
}

const Card: React.FC<CardProps> = ({ icon, title, content }) => {
	return (
		<div className={'cooperation-card'}>
			<div className={'icon'}>
				<FontAwesomeIcon icon={icon} />
			</div>
			<div className={'title'}>
				<h5>{title}</h5>
			</div>
			<div className={'content'}>
				<span>{content}</span>
			</div>
		</div>
	)
}

export default Card
