import React from 'react'
import classNames from 'classnames'
// import Map from './map'
import ContactInfo from "./contactInfo"
// import ContactMap from "./contactMap"

const ContactPage: React.FC = () => {
	return (
		<div id={'contact-page'} className={classNames('page')}>
			<ContactInfo />
			{/*<ContactMap />*/}
		</div>
	)
}

export default ContactPage
