import React from 'react'
import classNames from 'classnames'
import { Result, Button } from 'antd'
import {Link} from "react-router-dom"

const UnderConstructionPage: React.FC = () => {


	return (
		<div id={'under-construction-page'} className={classNames('page')}>
			<div className={classNames('container')}>
				<Result
					status="404"
					title="Připravujeme"
					subTitle="Tato stránka je v současné době ve výrobě a již brzy bude dostupná."
					extra={<Link to={'/'} className={'ant-btn ant-btn-primary'}>Zpět na hlavní stránku</Link> }
				/>
			</div>
		</div>
	)
}

export default UnderConstructionPage
