import React from 'react'
import classNames from 'classnames'
import Banner from './banner'
import About from './about'
import Cooperation from './cooperation'
import Services from './services'
import Testimonial from "./testimonial"

const HomePage: React.FC = () => {
	return (
		<div id={'home-page'} className={classNames('page')}>
			<Banner />
			<About />
			<Cooperation />
			<Services />
			<Testimonial />
		</div>
	)
}

export default HomePage
