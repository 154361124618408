import React from 'react'
import { Menu, Icon, Button } from 'antd'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

type MainMenuProps = {} & RouteComponentProps

const MainMenu: React.FC<MainMenuProps> = ({ location: { pathname } }) => {
	return (
		<Menu id={'main-menu'} mode={'horizontal'} selectedKeys={[pathname]}>
			<Menu.Item key={'/tax-calendar'}>
				<Link to={'/tax-calendar'}>Daňový kalednář</Link>
			</Menu.Item>
			<Menu.Item key={'/download'}>
				<Link to={'/download'}>Ke stažení</Link>
			</Menu.Item>
			<Menu.Item key={'/blog'}>
				<Link to={'/blog'}>Blog</Link>
			</Menu.Item>
			<Menu.Item key={'/contact'}>
				<Link to={'/contact'}>
					<Button type={'primary'}>Kontakt</Button>
				</Link>
			</Menu.Item>
		</Menu>
	)
}

export default withRouter(MainMenu)
