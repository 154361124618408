import React from 'react'
import classNames from 'classnames'
import { Button, Col, Row } from 'antd'
import { Container } from '../../../components'

const About: React.FC = () => {
	return (
		<Container
			id={'about'}
			title={'Něco málo o mě'}
			subtitle={''}
		>
			<Row>
				<Col span={24}>
					<span className={classNames('text')}>
						Úspěšně jsem ukončila studium na Fakultě financí a účetnictví Vysoké školy ekonomické v Praze se zaměření na daně a účetnictví. Od ukončení studia samostatně spravuji účetnictví, podvojné a okrajově i mzdové, daňovou evidenci, daňové přiznání a poskytuji daňové poradenství desítkám klientů. V roce 2019 jsem složila kvalifikační zkoušku na daňového poradce a jsem členkou Komory daňových poradců České republiky. V rámci KDPČR jsem se uvázala podpisem Etického kodexu k etickému, poctivému a férovému chování vůči klientům a kontinuálnímu vzdělávaní, které jediné zabezpečí plnění daňových a účetních povinnosti správně a řádně. Být účetní a daňařkou mě baví, což je poznat  i z mé práce. S klienty si vybudovávám profesionální ale přátelský vztah.
					</span>
				</Col>
				<Col span={24}>
					<span className={classNames('signature')}>Veronika Koňaková</span>
				</Col>
				<Col span={24}>
					<Button ghost={true} href={process.env.PUBLIC_URL + '/download/osvedceni.pdf'} target={'_blank'}>Osvědčení daňového poradce</Button>
					<Button type={'link'} href={process.env.PUBLIC_URL + '/download/eticky_kodex.pdf'} target={'_blank'}>Etický kodex KDPČR</Button>
				</Col>
			</Row>
		</Container>
	)
}

export default About
