import React from 'react'
import { Col, Row } from 'antd'
import Card from './card'
import { Container } from '../../../components'
import { faFileSignature, faHandHoldingUsd, faHandshake } from '@fortawesome/free-solid-svg-icons'

const Cooperation: React.FC = () => {
	return (
		<Container
			id={'cooperation'}
			title={'Spolupráce'}
			subtitle={
				'Začátek spolupráce mezi mnou a klientem je obykle postaven na třech jednouchých bodech'
			}
		>
			<Row gutter={30}>
				<Col sm={24} md={24} lg={8}>
					<Card
						icon={faFileSignature}
						title={'Kontakt a schůzka'}
						content={
							'Na základě vzájemné písemné nebo telefonické komunikace se domluvíme na osobní schůzce, kde bychom se seznámili, předali si důležité informace a rovněž své představy a vize o budoucí spolupráci.'
						}
					/>
				</Col>
				<Col sm={24} md={24} lg={8}>
					<Card
						icon={faHandHoldingUsd}
						title={'Dotazník a cena'}
						content={
							'Pro každého klienta jsou ceny stanoveny individuálně v závislosti na druhu podnikání, množství dokladů a náročnosti zpracování. Po vyplnění dotazníku s informacemi o rozsahu spolupráce Vám stanovím orientační cenu.'
						}
					/>
				</Col>
				<Col sm={24} md={24} lg={8}>
					<Card
						icon={faHandshake}
						title={'Podpis smlouvy'}
						content={
							'Návrh smlouvy posílám klientovi k vyjádření a konstruktivní debatě. Po odsouhlasení se smlouva podepíše a začíná se spolupráce, která bude, doufám,  pro obě strany přínosná.'
						}
					/>
				</Col>
			</Row>
		</Container>
	)
}

export default Cooperation
